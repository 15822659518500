import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { PATHS } from 'config/paths';

class SimpleMenu extends Component {
    render() {
        return (
            <section id="main-menu">
                <Container className="text-center">
                    <a href={PATHS.EXTERNAL.HOME}>
                        <img src="" className="my-3" alt="cukrzyca" />
                    </a>
                </Container>
            </section>
        );
    }
}

export default SimpleMenu;
