import { ReactComponent as apple } from 'assets/icons/holidays/apple.svg';
import { ReactComponent as battery } from 'assets/icons/holidays/battery.svg';
import { ReactComponent as easter } from 'assets/icons/holidays/easter.svg';
import { ReactComponent as firstDayOfAutumn } from 'assets/icons/holidays/first-day-of-autumn.svg';
import { ReactComponent as firstDayOfSummer } from 'assets/icons/holidays/first-day-of-summer.svg';
import { ReactComponent as flower } from 'assets/icons/holidays/flower.svg';
import { ReactComponent as halloween } from 'assets/icons/holidays/halloween.svg';
import { ReactComponent as majowka } from 'assets/icons/holidays/majowka.svg';
import { ReactComponent as polishIndependence } from 'assets/icons/holidays/polish-independence.svg';
import { ReactComponent as summerHoliday } from 'assets/icons/holidays/summer-holiday.svg';
import { ReactComponent as trainingCamp } from 'assets/icons/holidays/training-camp.svg';
import { ReactComponent as valentinesDay } from 'assets/icons/holidays/valentines-day.svg';
import { ReactComponent as winter } from 'assets/icons/holidays/winter.svg';
import { ReactComponent as world } from 'assets/icons/holidays/world.svg';
import { ReactComponent as christmas } from 'assets/icons/holidays/star.svg';
import packageJson from '../package.json';

export const LANGUAGE = 'pl';

export const SENTRY_DSN =
    'https://6125bef1328a45e98c47457321bfc948@o251048.ingest.sentry.io/1436785';

export const DIET_ID_CLASSIC = 3;
export const DIET_ID_VEGE = 4;

export const APP_STORE_URL = '';
export const GOOGLE_PLAY_URL = '';

export const FLASH_MESSAGES = {
    AUTH: {
        REMIND_PASSWORD: {
            SUCCESS: 'auth.remindPassword.success',
        },
        RESET_PASSWORD: {
            SUCCESS: 'auth.resetPassword.success',
            FAILED: 'auth.resetPassword.invalidToken',
        },
        SET_INITIAL_PASSWORD: {
            NO_PASSWORD: 'auth.setInitialPassword.noPassword',
            SUCCESS: 'auth.setInitialPassword.success',
        },
    },
    COUPON: {
        SUCCESS: 'coupon.success',
    },
    PROMO_CODE: {
        SUCCESS: 'promoCode.success',
    },
    CART: {
        INVALID_PRODUCT_ID: 'cart.invalidProductId',
    },
};

export const holidayIcons = {
    apple,
    battery,
    easter,
    'first-day-of-autumn': firstDayOfAutumn,
    'first-day-of-summer': firstDayOfSummer,
    flower,
    halloween,
    majowka,
    'polish-independence': polishIndependence,
    'summer-holiday': summerHoliday,
    'training-camp': trainingCamp,
    'valentines-day': valentinesDay,
    winter,
    world,
    christmas,
};

export const MENU = {
    SIMPLE: 'simple',
    FULL: 'full',
};

export const SYSTEM_OF_MEASURES = {
    SI: 'si',
    IMPERIAL: 'imperial',
};

export const APP_TAB_DIET = 'diet';
export const APP_TAB_MEASUREMENTS = 'measure';
export const APP_TAB_SHOPPING_LIST = 'shopping-list';
export const APP_TAB_HYDRATION = 'hydration';
export const APP_TAB_MORE = 'more';
export const APP_TAB_PROFILE = 'profile';
export const APP_TABS = [
    APP_TAB_DIET,
    APP_TAB_MEASUREMENTS,
    APP_TAB_SHOPPING_LIST,
    APP_TAB_HYDRATION,
    APP_TAB_MORE,
    APP_TAB_PROFILE,
];

export const UNIT_TYPES = {
    BODY_WEIGHT: {
        SI: 'kg',
        IMPERIAL: 'lb',
    },
    LENGTH_BODY_MEASUREMENT: {
        SI: 'cm',
        IMPERIAL: 'in',
    },
    VOLUME: {
        SI: 'ml',
        IMPERIAL: 'oz',
    },
};

export const disableCodForProductsArray = [324, 325, 326];

export const FRISCO_TRACKING_URL = '';
export const IMAGE_SHARING_URL = '';

export const APP_VERSION = packageJson.version;
