import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import activerse from 'assets/images/activerse_logo_horizontal.svg';
import { PATHS } from '../config/paths';

class Footer extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        forceReloadLinks: PropTypes.bool,
    };

    static defaultProps = {
        forceReloadLinks: false,
    };

    state = {
        isOpen: false,
    };

    renderNavLink(href, text) {
        if (this.props.forceReloadLinks) {
            return (
                <a className="nav-link" href={href}>
                    {text}
                </a>
            );
        }

        return (
            <Link className="nav-link" to={href}>
                {text}
            </Link>
        );
    }

    handleClick = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };

    render() {
        return (
            <footer>
                <Container>
                    <Row>
                        <Col md="6" className="text-center text-md-left">
                            <a href={PATHS.EXTERNAL.HOME}>
                                <img
                                    src=""
                                    alt="cukrzyca"
                                    style={{ width: '156px' }}
                                    className="img-fluid"
                                />
                            </a>
                        </Col>

                        <Col
                            xs={{ size: 6, order: 1 }}
                            md={{ size: 6, order: 2 }}
                            className="mt-4 mt-md-0"
                        >
                            <Nav vertical className="align-items-md-end">
                                <NavItem>
                                    {this.renderNavLink(
                                        '/how-to',
                                        this.props.t('footer/service-access')
                                    )}{' '}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/terms-of-service',
                                        this.props.t('footer/tos')
                                    )}{' '}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/privacy-policy',
                                        this.props.t('footer/privacy-policy')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        PATHS.CONTACT,
                                        this.props.t('footer/contact')
                                    )}
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md="6" className="text-center text-md-left">
                            <p className="description">
                                proudly created by{' '}
                                <a href="https://activerse.app/">
                                    <img
                                        src={activerse}
                                        className="ms-2"
                                        alt="activerse.app"
                                    />
                                </a>
                            </p>
                        </Col>
                        <Col md="6">
                            <p className="description text-center text-md-right">
                                Copyright ©2022 activerse.app
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default withLocale(Footer);
