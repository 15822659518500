import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO({ description, lang, meta, title }) {
    const siteMetadata = {
        shortName: 'Cukrzyca',
        name: 'Cukrzyca',
        themeColor: '#ffffff',
        title: 'Cukrzyca',
        description: '',
        keywords: [''],
        author: 'activerse',
        icons: [
            {
                src: '/meta/icon-192.png',
                sizes: '192x192',
                type: 'image/png',
            },
            {
                src: '/meta/icon-310.png',
                sizes: '310x310',
                type: 'image/png',
            },
            {
                src: '/meta/icon-maskable-192.png',
                sizes: '192x192',
                type: 'image/png',
            },
            {
                src: '/meta/icon-maskable-310.png',
                sizes: '310x310',
                type: 'image/png',
            },
        ],
    };

    const icons = siteMetadata.icons.map(icon => ({
        rel: 'icon',
        key: icon.src,
        type: icon.type,
        sizes: icon.sizes,
        href: icon.src,
    }));

    const appleTouchIcons = siteMetadata.icons.map(icon => ({
        rel: 'apple-touch-icon',
        key: icon.src,
        type: icon.type,
        sizes: icon.sizes,
        href: icon.src,
    }));

    const metaDescription = description || siteMetadata.description;
    let siteTitle;

    if (title) {
        siteTitle = `${title} — ${siteMetadata.title}`;
    } else {
        siteTitle = siteMetadata.title;
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={siteTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: siteTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: siteTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: 'keywords',
                    content: siteMetadata.keywords.join(', '),
                },
                {
                    name: 'theme-color',
                    content: siteMetadata.themeColor,
                },
                {
                    name: 'apple-mobile-web-app-title',
                    content: siteMetadata.shortName,
                },
                {
                    name: 'apple-mobile-web-app-status-bar-style',
                    content: 'black-translucent',
                },
                {
                    name: 'apple-mobile-web-app-capable',
                    content: 'yes',
                },
            ].concat(meta)}
            link={[
                {
                    rel: 'manifest',
                    href: '/manifest.json',
                },
                {
                    rel: 'shortcut icon',
                    type: 'image/vnd.microsoft.icon',
                    href: '/favicon.ico',
                    sizes: '96x96 32x32 16x16',
                },
            ]
                .concat(icons)
                .concat(appleTouchIcons)}
        />
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    title: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

export default SEO;
