export default {
    'meal/exchange-section-title': 'You can change for',
    menu: 'Menu',
    'menu/home': 'Home',
    'menu/diet': 'Meal plan',
    'menu/diet-plan': 'Meal plan',
    'menu/trainings': 'Workouts',
    'menu/mindfulness': 'Balance',
    'menu/shop': 'Shop',
    'menu/knowledge': 'Knowledge',
    'menu/blog': 'Blog',
    'menu/contact': 'Contact',
    'menu/coach': 'Coach',
    'menu/activities': 'Activities',
    'menu/shopping-list': 'Shopping list',
    'menu/measurements': 'Measurements',
    'menu/diet/settings': 'Meal plan settings',
    'menu/fav-meals': 'Favorite meals',
    'menu/logout': 'Log out',
    'menu/login': 'Log in',
    'menu/settings': 'Settings',
    'menu/account': 'Account',
    logout: 'Logout',
    save: 'Save',
    cancel: 'Cancel',
    insert: 'Include',
    delete: 'Delete',
    next: 'Next',
    back: 'Back',
    print: 'Print',
    ok: 'OK',
    add: 'Add',
    close: 'close',
    buy: 'Buy',
    bestseller: 'bestseller',
    purchase: 'Purchase',
    from: 'from',
    'data-saved': 'Data saved',
    'error/occurred': 'Error occurred',
    'error/message/generic': 'Something went wrong',
    'error/message/network': 'Network error',
    'error/message/offline': 'You are offline',
    'error/message/no-account': 'Don’t have an account yet?',
    'error/additional-message/generic':
        'Incident has been reported and we will work to solve it.',
    'error/additional-message/not-found': 'Page does not exist',
    'error/additional-message/user-not-found':
        'We didn’t find your account among registered users.',
    'button/refresh': 'Refresh',
    'button/try-again': 'Try again',
    'button/go-back': 'Back',
    'button/get-access': 'Get access',
    'button/return': 'Return',
    'button/send-email': 'Send email',

    week: 'week',
    week_plural: '{{count}} weeks',
    month: 'month',
    month_plural: '{{count}} months',
    year: 'year',
    year_plural: '{{count}} years',

    'diet-intro/title': "HELLO,\nCongratulations and I'm glad you are here!",
    'diet-intro/p0':
        'You have made the most important decision. Let me help you on your way to your goal: your dream body is closer than you think. A balanced diet individually tailored to your needs is your key to success.',

    'diet-intro/start-diet': 'Start your meal plan',

    'diet-intro/p1/title': "Eat regularly and don't skip any meals",
    'diet-intro/p1/content':
        "I have prepared 4 healthy and tasty meals for you. Try to eat breakfast within an hour of waking up. Have your next meals every 2-3 hours and eat the last one at least 2 hours before going to bed. Don't skip any meal - all of them are important!",

    'diet-intro/p2/title': 'Remember to stay hydrated',
    'diet-intro/p2/content':
        'In your menu you will find suggestions of drinks that you can have during the day. Drink mainly water - in total, consume about 1.5-2 liters of fluid a day. During intense activity or hot weather, your body may need more fluid. You can also drink herbal teas, sparkling water or water with the addition of a few slices of fresh cucumber, lime, lemon or strawberries. This is a great idea if you want to add extra flavor! Cut down on caffeinated drinks. Try to drink water throughout the day, in small portions. Thanks to this, you will hydrate your body better. Remember that you can control the amount of water you drink through the hydration function in my app!',

    'diet-intro/p3/title': 'Cook fast, healthy and tasty meals',
    'diet-intro/p3/content':
        'Follow the instructions and you will easily make all your meals! Remember to thoroughly wash and peel all products that require it before using them (e.g. vegetables, fruit). I have prepared your menu in such a way that the dinner will be the same for two days. All this to save the time you spend in the kitchen and make cooking easier. You will find 4 balanced meals in your plan.',

    'diet-intro/p4/title': 'Plan your shopping',
    'diet-intro/p4/content':
        'Go to the shopping list - you always have it with you on your phone! All you need to do is select the date range and you will receive a ready shopping list that will help you save the time you spend shopping.',

    'diet-intro/p5/title': 'Eat what you like and achieve your goals',
    'diet-intro/p5/content':
        "You can swap ingredients you don't like, meals you don't feel like eating or even entire menus! Click the arrow icon next to a given product and replace it with another one, change the entire dish by selecting the replacement button at the bottom of the meal, or the entire day of the menu by going to the bottom of the menu and clicking the day change button. Choose one of the alternative proposals that I have prepared for you. Thanks to this, your menu will still remain balanced in accordance with the assumed caloric value and the amount of individual macronutrients (proteins, fats and carbohydrates), so you do not have to worry about achieving your goals",

    'diet-intro/p6/title': 'Adjust the order of meals to your needs',
    'diet-intro/p6/content':
        'Would you rather switch your lunch with the snack? No problem. The most important thing is that you have all of your scheduled meals at more or less regular intervals throughout the day.',

    'diet-intro/p7/title': 'Everything under control',
    'diet-intro/p7/content':
        'Add your body measurements systematically: weight and circumference. Thanks to the application, you can conveniently track where you are on your way. It is also an additional motivation to continue working when you see how much you have already achieved!',

    'diet-intro/p8/title': 'Stay in shape!',
    'diet-intro/p8/content':
        "Diet is essential, but you can't skip physical activity. Workouts should be tailored to your individual needs and capabilities. This is why I have prepared so many training programs that you can take advantage of! It doesn't matter whether you are just starting your adventure with training or you have already set yourself a specific goal, whether you want to improve your condition or strengthen your abs or leg muscles. You will certainly find something for yourself! You don't need to have any equipment, all you need is a little space and a phone. See for yourself.",

    'diet-intro/p9/title': 'We are here for you',
    'diet-intro/p9/content':
        'Should you have any questions, check the list of FAQs. If you still have doubts about your diet, write to my team via the contact form. Our professional nutritionists and trainers are ready to help resolve any issues you may have.',

    'diet-intro/sign': 'Best of luck!\nML',

    'meal/preparation-time': 'Preparation time',
    'diet/meal/seasoning/header': 'Seasonings',
    'diet/meal/optionalIngredients/header': 'You can also add',
    'auth/confirm/header': 'Thank you',
    'auth/confirm/content':
        'Your account is active, so you can start using cukrzyca app at any moment!',
    'auth/confirm/mobileApps':
        'Don’t have cukrzyca app yet? <b>Download it now!</b>',
    'meal/min': 'min.',
    'meal/how-to-prepare': 'How to prepare',
    'meal/proteins-short': 'P',
    'meal/fat-short': 'F',
    'meal/carbohydrates-short': 'C',
    'meal/proteins': 'Proteins',
    'meal/fat': 'Fats',
    'meal/carbohydrates': 'Carbs',
    'meal/weekday0': 'Sunday',
    'meal/weekday1': 'Monday',
    'meal/weekday2': 'Tuesday',
    'meal/weekday3': 'Wednesday',
    'meal/weekday4': 'Thursday',
    'meal/weekday5': 'Friday',
    'meal/weekday6': 'Saturday',
    'meal/weekday0-short': 'Sun',
    'meal/weekday1-short': 'Mon',
    'meal/weekday2-short': 'Tue',
    'meal/weekday3-short': 'Wed',
    'meal/weekday4-short': 'Thu',
    'meal/weekday5-short': 'Fri',
    'meal/weekday6-short': 'Sat',
    'meal/exchange-meal-button': 'Change',
    'meal/exchange-dish-button': 'Change',
    'meal/exchange-collapse': 'Cancel',
    'meal/exchange-day-button': 'Change day',
    'meal/exchange-ingredient': 'Change ingredient',
    'meal/exchange-for': 'You can change for',
    'meal/original-product': 'Original product',
    'meal/original-meal': 'Original meal',
    'meal/original-day': 'Original menu',
    'meal/summary-title': 'Calorific distribution',
    'meal/print-button': 'Print',
    'meal/print-period-alert': 'Printing available for less than 2 weeks.',
    'meal/show-menus-contain': 'Show menus containing:',
    'meal/menus-contain': 'contains:',
    'meal/search': 'Search',
    'meal/name-of-dish-or-ingredient': 'the dish or ingredient name',
    'meal/there-are-no-replacements': 'There are no suitable replacements.',
    'meal/supports': 'Supports:',
    'meal/copy-day-button': 'Copy for tomorrow',
    'meal/copy-day-button-done': 'Copied',
    'meal/eaten-button': 'Eaten',
    'meal/ingredients': 'Ingredients',
    'meal/favourite-meals': 'Favorite meals',
    'meal/show-favourite-meals': 'Search for favorite meal:',
    'meal/add-to-dayplan': 'Add to your plan',
    'meal/meal-details': 'Meal details',
    'meal/favourite-meals/no-meals':
        'Adding meals to favorites will make it easy to add them to your menu once again.',
    'meal/favourite-meals/no-search-results': 'No results in searched meals.',

    'next-day-notification': 'You have to prepare something for tomorrow:',
    'alert/holiday-notification':
        'Show <strong>special menu</strong> for this day.',

    'dish/portion/you-should-have-this-dish-last':
        'You have prepared this meal recently. If there’s anything left, it will not be included in your meal plan.',
    'dish/portion/you-should-have-this-dish':
        'You have prepared this meal recently.',
    'dish/portion/rest': 'The rest will not be used in you diet plan.',

    'dish/portion/eat-x-of': 'Eat one serving of {{total}}.',
    'dish/portion/eat-x-of_plural':
        'Eat {{fraction}}&nbsp;servings of {{total}}.',

    'dish/portion/eat-x-portion': 'Eat one serving.',
    'dish/portion/eat-x-portion_plural': 'Eat {{fraction}}&nbsp;servings.',

    'dish/portion/x-portion-of': ' - one serving of {{total}}',
    'dish/portion/x-portion-of_plural':
        ' - {{fraction}}&nbsp;servings of {{total}}',

    'recipe-for-x-portion': 'Recipe for one serving',
    'recipe-for-x-portion_plural': 'Recipe for {{count}} serving',

    'dish/portion/dish-used-in-following-days':
        'This dish will be used in the following days. The recipe is for one serving. ',
    'dish/portion/dish-used-in-following-days_plural':
        'This dish will be used in the following days. The recipe is for {{count}} servings.',

    'shopping-list/title': 'Shopping list',
    'shopping-list/export-to-frisco': 'Export to Frisco.pl',
    'shopping-list/date-range': 'Date range',
    'shopping-list/show-list-button': 'Show list',
    'shopping-list/alert/no-full-covered-by-diet-in-period':
        'You do not have a meal plan for the entire date range. The shopping list contains products only from the days on which the diet was arranged.',
    'shopping-list/alert/no-diet-in-period':
        'No meal plan in the given date range.',
    'shopping-list/bought': 'Bought',
    'shopping-list/no-products': 'No products',

    'measurments/title': 'Measurements',
    'measurments/current-measurments-title': 'Last measurements',
    'measurments/history': 'History of measurements',
    'measurments/edit-measurments-title': 'Edit measurements',
    'measurments/add-measurments-button': 'Add measurements',
    'measurments/delete-measurment-button': 'Delete current measurement',
    'measurments/date': 'Date',
    'measurments/alert-no-measurments-in-period':
        'No measurements of a given type in a given period of time',
    'measurments/param/body-weight': 'Body weight',
    'measurments/param/hips': 'Hips',
    'measurments/param/hip': 'Hip',
    'measurments/param/waist': 'Waist',
    'measurments/param/thighs': 'Thighs',
    'measurments/param/thigh': 'Thigh',
    'measurments/param/chest': 'Chest',
    'measurments/param/arm': 'Arm',
    'measurments/state/no-measurement': 'no measurement',
    'measurments/state/change': 'change',
    'measurments/state/no-change': 'no change',
    'no-data': 'not specified',
    'no-change': 'no change',
    'redirect/diet-settings/title': 'Diet plan',
    'redirect/diet-settings/message': 'Start the diet to see the diet plan.',
    'redirect/shopping-list/message':
        'Start your meal plan to see the shopping list.',
    'redirect/diet-settings/button': 'Start the meal plan',

    'diet-settings/title': 'Meal plan settings',
    'diet-settings/sex': 'Sex',
    'diet-settings/sex/male': 'Male',
    'diet-settings/sex/female': 'Female',
    'diet-settings/name': 'Name',
    'diet-settings/current-body-weight': 'Current body weight',
    'diet-settings/height': 'Height',
    'diet-settings/birth-date': 'Date of birth',
    'diet-settings/your-goal': 'Your goal',
    'diet-settings/goal/slimming': 'Lose weight with ease',
    'diet-settings/goal/slimming/description':
        'Get in shape and drop extra pounds',
    'diet-settings/goal/stabilization': 'Maintain my weight',
    'diet-settings/goal/stabilization/description': 'Embrace your  body',
    'diet-settings/goal/put-on-weight': 'Gain weight',
    'diet-settings/goal/put-on-weight/description':
        'Build strength and gain extra pounds',
    'diet-settings/goal-weight': 'Goal weight',
    'diet-settings/triangle/intro':
        'Choose the areas that your meal plan will support',
    'diet-settings/triangle/body': 'Body',
    'diet-settings/triangle/body/desc':
        'a healthy body is physically fit and resistant to harmful external factors. If you need dietary support in this area, select this option.',
    'diet-settings/triangle/mind': 'Mind',
    'diet-settings/triangle/mind/desc':
        "If your brain must operate at full capacity every day and you can't afford problems with concentration, then this is the right option for you.",
    'diet-settings/triangle/libido': 'Libido',
    'diet-settings/triangle/libido/desc':
        'If you select this area, I will provide your meal plan with products and spices that have been considered aphrodisiacs for centuries, and are also rich in ingredients that indirectly affect your body, improving its entire blood supply.',
    'diet-settings/diet-type/question': 'Diet mode',

    'diet-settings/diet-type/classic/description':
        'Diet excludes wheat and its derivatives as well as cow’s milk. The protein source is mainly meat, fish, dairy products and eggs.',

    'diet-settings/diet-type/vege/description':
        'Diet excludes meat, fish, seafood, wheat and its derivatives as well as cow’s milk. However, it includes cheese, fermented milk products, eggs and legume seeds.',
    'diet-settings/activity/question':
        'How much do you exercise on average per week?',
    'diet-settings/activity/none':
        'I exercise occasionally, or not at all - an office job',
    'diet-settings/activity/low': 'I exercise 1-3 hours a week',
    'diet-settings/activity/medium':
        'I exercise 3-5 hours a week (strength exercises)',
    'diet-settings/activity/high':
        'I exercise 3-5 hours a week (aerobic exercises, running, spinning)',
    'diet-settings/final/text':
        'What should you consider when starting or changing your meal plan?',
    'diet-settings/final/list/1':
        'arrange the time for shopping for the products you need for the diet',
    'diet-settings/final/list/2':
        'if you are planning a trip that will prevent you from complying with the menu, postpone the start of the diet to a more convenient time.',
    'diet-settings/final/list/3':
        'do not delay the start of the diet - if you have already taken the first step, go ahead',
    'diet-settings/final/list/4': 'you have 15 days to start the diet',
    'diet-settings/final/start-date-question':
        'When would you like to begin this meal plan?',
    'recommended-on-psychotest': '(recommended on the basis of the test)',

    'diet-settings/welcome': 'Welcome',
    'diet-settings/welcome-message':
        'Please answer a few questions to help us prepare a meal plan that is just right for you.',
    'diet-settings/cta': 'Begin',
    'diet-settings/change-your-diet': 'Set your new meal plan ',
    'diet-settings/sex/label': 'Gender',
    'diet-settings/goal/label': 'Select the goal you want to achieve',
    'diet-settings/goal/label/intro': 'Your goal',
    'diet-settings/diet-id/label': 'Choose the meal plan you’d\nlike to use',
    'diet-settings/diet-id/label/intro': 'Meal plan',
    'diet-settings/diet-type/classic': 'Classic Zone',
    'diet-settings/diet-type/vege': 'Healthy Vegetarian',
    'diet-settings/activity-level/label': 'How fit are you?',
    'diet-settings/activity-level/label/intro': 'Activity level',
    'diet-settings/activity-level/none/title': 'Newbie',
    'diet-settings/activity-level/none/description':
        "I haven't worked out in a while",
    'diet-settings/activity-level/low/title': 'About average',
    'diet-settings/activity-level/low/description':
        'I work out from time to time',
    'diet-settings/activity-level/medium/title': 'Fit',
    'diet-settings/activity-level/medium/description':
        'I work out regularly (aerobic exercises, running, spinning)',
    'diet-settings/activity-level/high/title': 'Pro',
    'diet-settings/activity-level/high/description':
        'I work out regularly (strength exercises)',
    'diet-settings/advice':
        'Remember to plan your shopping time before starting your diet.',
    'diet-settings/finish': 'We are preparing your meal plan',
    'diet-settings/please-wait': 'Please wait...',
    'diet-settings/changed': 'Your meal plan settings were changed.',

    'settings/title': 'Settings',
    'settings/profile': 'User profile',
    'settings/email': 'E-mail',
    'settings/metric-system': 'Metric system',
    'settings/si': 'SI system',
    'settings/imperial': 'Imperial system',
    'settings/notifications': 'Notifications',
    'settings/agreements/diet-course': 'The course of the meal plan',
    'settings/agreements/articles': 'Motivation and articles',
    'settings/agreements/newsletter': 'Newsletter',
    'settings/agreements/newsletter-info':
        'Unsubscribing may take up to two hours.',
    'settings/change-password': 'Change password',
    'settings/change-password/current-password': 'Current password',
    'settings/change-password/new-password': 'New password',
    'settings/change-password/success': 'Password was changed',
    'settings/products/access/end-date {date}': 'Access paid until {date}.',
    'settings/products/access/past-end-date {date}': 'Access expired {date}.',
    'settings/products/access/from-subscription':
        'Access due to a subscription.',
    'settings/products/access/lifetime': 'Livetime access.',
    'settings/products/access/none': 'None.',
    'settings/products/diet/heading': 'Meal plan',
    'settings/products/heading': 'Active products',
    'settings/products/video-workouts/heading': 'Trainings',
    'settings/subscriptions/cancellation-instructions':
        'Cancellation instructions',
    'settings/subscriptions/heading': 'Active subscriptions',
    'settings/subscriptions/diet/heading': 'Diet',
    'settings/subscriptions/video-workouts/heading': 'Trainings',
    'settings/subscriptions/diet+video-workouts/heading': 'Diet and trainings',
    'settings/subscriptions/none': 'None.',
    'settings/subscriptions/renewal-date {date}': 'Next renewal: {date}.',
    'settings/subscriptions/renewal-date/in-past':
        'Renewal is being processed.',
    'settings/subscriptions/type/diet+video-workouts':
        'Access to diet and trainings.',
    'settings/subscriptions/type/diets': 'Access to diet.',
    'settings/subscriptions/type/video-workouts': 'Access to trainings.',
    'settings/hydration': 'Hydration',
    'settings/hydration/daily-goal': 'Daily goal',
    'settings/remove-account': 'Account deletion',

    'remove-account/title': 'Deleting account',
    'remove-account/app-store/disclaimer':
        '<b class="semi-bold">Warning! Deleting an account does not cancel the subscription.</b> <p class="mt-2">You can manage active subscriptions in your Apple ID account settings on your iPhone.</p>',
    'remove-account/google-play/disclaimer':
        '<b class="semi-bold">Warning! Deleting an account does not cancel the subscription.</b> <p class="mt-2">You can manage active subscriptions in the settings of the Google Play app.</p>',
    'remove-account/stripe/disclaimer':
        'Deleting your account will cancel your subscription.',
    'remove-account/email-confirm-info':
        'The account deletion process requires verification of your account. For this purpose, we will send you an e-mail to the following address: {{userEmail}}',
    'remove-account/message-sent': 'Message has been sent',
    'remove-account/link-expire-info': 'The link will expire after 24 hours.',
    'remove-account/check-your-mailbox':
        'Check your e-mail. Click the link in the message we have sent you. <br /> <b class="medium">Your account will not be deleted until you do so.</b>',
    'remove-account/confirmation':
        'I confirm, I want to delete my account from the application.',
    'remove-account/info-title': 'After deleting your account:',
    'remove-account/info_1':
        'you won’t be able to log in to your account, you will lose access to the application',
    'remove-account/info_2':
        'you will lose all your progress, history, rewards etc. for training, diet and other activities in the app',
    'remove-account/info_3': 'your account data will be irretrievably deleted',
    'remove-account/error': 'Something went wrong',
    'remove-account/try-in-moment': 'Please try again in a moment.',
    'remove-account/link-expired': 'The link has expired.',
    'remove-account/link-expired-info':
        'Restart deleting account process to receive a new e-mail with updated link.',
    'remove-account/success': 'Your account has been deleted',
    'remove-account/success-info': 'You will be logged out for a moment.',

    'footer/service-access': 'Access and payments',
    'footer/tos': 'Terms of service',
    'footer/privacy-policy': 'Privacy policy',
    'footer/contact': 'Contact',
    'footer/secure-payments': 'Secure payments guaranteed by',
    'button/buy-diet': 'Buy diet plan',
    'button/buy-subscribtion': 'Buy a subscription',
    'button/buy': 'Buy',
    'button/buy-now': 'Buy now',
    'button/remove-account': 'Delete the account',
    'button/retry': 'Try again',

    'error/unauthorized/subscription/expired/title':
        'Subscription expired\n{{accessTo}}',
    'error/unauthorized/subscription/expired/content':
        'To continue using the application, buy a subscription.',
    'error/unauthorized/subscription/never-had/title':
        'Start your transformation today!',
    'error/unauthorized/subscription/never-had/content':
        'You are one step closer to starting your meal plan! Remeber, the sooner you start, the faster you see the results!',
    'error/unauthorized/subscription/refresh': 'Try again',
    'error/unauthorized/subscription/restore-purchuases': 'Restore purchases',
    'info-pages/goal-reached/title':
        'You nailed it!\nYou have achieved your goal.',
    'info-pages/goal-reached/content': "It's time to decide what to do next.",
    'info-pages/goal-reached/button': 'Adjust the meal plan',
    'info-pages/goal-lost/title':
        "You were brave, let's get you closer to your goal!",
    'info-pages/goal-lost/content':
        'Do you want to continue and make progress? Proceed to your meal plan, you got this!',
    'info-pages/goal-lost/button': 'Change the meal plan',
    'info-pages/current-weight-needed/title': 'Add current measurements',
    'info-pages/current-weight-needed/content':
        'To prepare a diet plan for the next days, we need your current measurements.',
    'info-pages/current-weight-needed/button': 'Add current measurements',
    'info-pages/diet-available-in-future/title':
        'Your meal plan will be available in {{count}} days',
    'info-pages/diet-available-in-future/title_plural':
        'Your meal plan will be available in {{count}} days',
    'info-pages/diet-available-in-future/content':
        'A new plan can be prepared at the earliest 7 days before the meal plan begins. Remember to enter your measurements in the meantime!',
    'info-pages/diet-outside-access/title':
        'Your subscription ends in one day, at {{date}}',
    'info-pages/diet-outside-access/title_plural':
        'Your subscription ends in {{count}} days, at {{date}}',
    'info-pages/diet-outside-access/web-title':
        'Your subscription ends at {{date}}',
    'info-pages/diet-outside-access/web-title_plural':
        'Your subscription ends at {{date}}',
    'info-pages/diet-outside-access/unknown-number': 'unknown number of',
    'info-pages/diet-starts-tomorrow/title':
        'The meal plan is ready.\nYou start tomorrow!',
    'info-pags/diet-start-tomorrow/content':
        'Remember to get all the ingredients necessary to prepare the meals. You can find the list in the “Groceries” tab.',
    'info-pags/diet-start-tomorrow/button': 'Show groceries',

    'contact/title': 'Contact',
    'contact/faq': 'Frequently Asked Questions',
    'contact/contact-form': 'Contact form',
    'contact/name-surname': 'Name and surname',
    'contact/email-address': 'Your e-mail address',
    'contact/topic': 'Subject',
    'contact/topic/topic1': 'Problem with buying a plan',
    'contact/topic/topic2': 'Question to the dietician',
    'contact/topic/topic3': 'Question about trainings',
    'contact/topic/topic5': 'Complaints and returns',
    'contact/topic/topic7': 'Cooperation',
    'contact/message': 'Message',
    'contact/send-message': 'Send a message',
    'contact/send-success': 'Message was sent. Thank you.',
    'knowledge/title': 'Knowledge',
    'knowledge/show': 'Show:',
    'knowledge/all': 'All',
    'knowledge/nutrition': 'Nutrition',
    'knowledge/motivation': 'Motivation',
    'knowledge/workouts': 'Workouts',
    'knowledge/tips': 'Tips',
    'knowledge/back-to-article-list': 'Back to all articles',
    'knowledge/other-articles': 'Other articles',
    'knowledge/no-results': 'No articles found',
    'knowledge/load-more': 'More articles',
    'knowledge/read-more': 'Read more',
    'knowledge/back': 'Come back',
    'knowledge/article-details': 'Article details',

    'me/account/activate/message': 'Your account has been activated.',
    'make-decision/title': 'Time for decision!',
    'make-decision/start': 'Start',
    'make-decision/now': 'Now',
    'make-decision/goal': 'Goal',
    'make-decision/left': 'Left',
    'make-decision/put-on-weight': 'Put on weight',
    'make-decision/gain-muscle-mass': 'Gain muscle',
    'make-decision/weight-loose': 'Weight loss',
    'make-decision/stabilization': 'Stabilization',
    'make-decision/continue-slimming':
        'Do you want to lose more weight? Enter your new weight.',
    'make-decision/change-diet-mode-from-slimming-to-stabilization':
        "Looks like you've achieved your goal. It's time for stabilization if you want to maintain it. What time do you want to start it?",
    'make-decision/continue-stabilization':
        'If you want to continue the stabilizing diet despite your weight change, click on the button below. Remember that if you choose this option, your last weight measurement will be set as your current goal.',
    'make-decision/change-diet-mode-from-stabilization-to-slimming':
        'Your weight has increased therefore we recommend going to the weight loss diet, Enter your new goal weight and select the starting date.',
    'make-decision/change-diet-mode-from-stabilization-to-put-on-weight':
        'Your weight has decreased therefore we recommend going to the put on weight diet, Enter your new goal weight and select the starting date.',
    'make-decision/continue-put-on-weight':
        'If you want to keep put on weight, choose a new goal.',
    'make-decision/change-diet-mode-from-put-on-weight-to-stabilization':
        'You’ve achieved your goal weight. Select the day to begin the stabilization diet which will help you maintain your effects.',
    'make-decision/continue-gain-muscle-mass':
        'If you want to keep gain muscle mass, choose a new goal.',
    'make-decision/change-diet-mode-from-gain-muscle-mass-to-stabilization':
        'You’ve achieved your goal weight. Select the day to begin the stabilization diet which will help you maintain your effects.',

    'route-title/default': 'Cukrzyca',
    'route-title/day-plan': 'Meal plan',
    'route-title/diet-intro': 'Diet intro',
    'route-title/day-plan-with-date': 'Meal plan',
    'route-title/diet-will-be-available-in-future':
        'Diet will be available in future',
    'route-title/current-weight-needed': 'Current weight needed',
    'route-title/goal-reached': 'Goal reached',
    'route-title/goal-lost': 'Goal lost',
    'route-title/make-decision': 'Time for decision!',
    'route-title/shopping-list': 'Shopping list',
    'route-title/measurements': 'Measurements',
    'route-title/measurements-add': 'Add measurements',
    'route-title/measurements-edit': 'Edit measurements',
    'route-title/diet-settings': 'Meal plan settings',
    'route-title/settings': 'Settings',
    'route-title/login-by-token': 'Login by Token',
    'route-title/logout': 'Logout',
    'route-title/error-404': 'Error 404',
    'route-title/unauthorized-exception': 'Unauthorized exception',
    'route-title/content': '',
    'route-title/contact': 'Contact',
    'route-title/knowledge-category': 'Knowledge',
    'route-title/knowledge': 'Feed',
    'route-title/knowledge-article': '',
    'route-title/diet-outside-access': 'Cannot access',
    'route-title/block-cookies': '',
    'route-title/activities': 'My activities',
    'route-title/activities-index': 'Activities',
    'route-title/activities-add': 'Add activity',
    'route-title/activities-edit': 'Edit activity',
    'route-title/promo-code': 'Discount coupon',
    'route-title/error-network': 'Network error occured!',
    'route-title/unsubscribe': 'We are sorry that you are leaving',
    'route-title/error-application': 'Application Error',
    'route-title/mindfulness': 'Balance',
    'route-title/fav-meals': 'Favorite meals',
    'route-title/buy': 'Buy',
    'route-title/download-the-app': 'Download the app',
    'mobile-title/knowledge': 'Articles',

    'alert/periodic-measurements-1':
        'It is important for you to measure yourself periodically to keep track of your progress achieving your goal weight. This will help us calculate nutrition values specific to your goals!',
    'alert/periodic-measurements-2': 'Please remember to weigh today!',

    'auth/login/title': 'Log in',
    'auth/login/with-google': 'Continue with Google',
    'auth/login/with-facebook': 'Continue with Facebook',
    'auth/login/with-apple': 'Continue with Apple',
    'auth/login/agreement': '',
    'auth/login/back-link-text': 'Back to login',
    'auth/login/menu-link-text': 'Log in',
    'auth/login/error-message': 'Invalid e-mail or password.',
    'auth/login/user-exists': 'Account already exists. Please log in.',
    'auth/login/user-not-exists': 'Account does not exists. Please register.',
    'auth/login/social-media-login-header': 'Login with social media accounts',
    'auth/login/social-media-set-up':
        'Your account is already set up with social media account.',

    'auth/remind-password/title': 'Retrieve Password',
    'auth/remind-password/description':
        'Enter your e-mail and receive instructions to change your password.',
    'auth/remind-password/link-text': 'Forget the password?',
    'auth/remind-password/error-message':
        'We have no user with this e-mail address.',
    'auth/remind-password/success-message':
        'An email with instructions to change the password has been sent.',

    'auth/reset-password/title': 'Set a new password',
    'auth/reset-password/token-is-not-valid-error-message':
        'Your password reset link has expired, if you still want to reset it, use "I forgot my password" function again.',
    'auth/reset-password/error-message':
        'Password should be at least 4 characters long',
    'auth/reset-password/success-message':
        'New password has been set for your account.',

    'auth/password-is-not-set':
        "You don't have a password set yet. Click the button below to set a password.",
    'auth/set-password': 'Set password',
    'auth/set-password/title': 'Set password',

    email: 'E-mail address',
    password: 'Password',
    'password-to-login': 'Password',
    'new-password': 'New password',
    name: 'First name',

    'form/send': 'Send',

    'validation/required': 'Value is required.',
    'validation/date-format': 'Date format is incorrect.',
    'validation/email-format': 'Please enter a valid e-mail address',
    'validation/url-format': 'Url format is incorrect.',

    'activities/my-activities': 'My activities ',
    'activities/edit-activities': 'Edit activities ',
    'activities/disclaimer':
        'Remember that each change (adding or removing activity) prepares a new meal plan, different in food products used from the current plan.',
    'activities/total': 'Total: ',
    'activities/diet-plan': 'Meal plan',
    before_breakfast: 'Before breakfast',
    after_breakfast: 'After breakfast',
    before_dinner: 'Before dinner',
    after_dinner: 'After dinner',
    before_supper: 'Before supper',
    after_supper: 'After supper',
    'activities/start-diet': 'Start',
    'activities/diet-settings': 'Meal plan settings',
    'activities/add-activity': 'Add activity',
    'activities/add-activity-single': 'Add activity',
    'activities/add-activity-periodic': 'Add a cyclic activity',
    'activities/edit-activity': 'Edit activity',
    'activities/edit-single-activity': 'Edit activity',
    'activities/edit-periodic-activity': 'Edit all activities in cycle',
    'activities/remove-single-activity': 'Cancel activity',
    'activities/remove-periodic-activity': 'Cancel all activities in cycle',
    'activities/time-of-day': 'Time of day',
    'activities/type-of-activity': 'Type of activity',
    'activities/more': 'More',
    'activities/activity-time': 'Duration of activity',
    'activities/burned-calories': 'Energy expenditure',
    'activities/repeat-every': 'Repeat every',
    'activities/activity1': 'Running/fast walking',
    'activities/activity2': 'Workout',
    'activities/activity3': 'Cycling',
    'activities/activity4': 'Swimming',
    'activities/duration': 'Duration',
    'activities/training': 'Training',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    'promo-code/page-title': 'Enter discout coupon',
    'promo-code/input-label': 'Discount coupon',
    'promo-code/missing-value': 'Enter discout coupon',
    'promo-code/invalid-value': 'Invalid coupon',
    'promo-code/submit': 'Verify coupon',
    'promo-code/not-exists': 'Promocode is not valid',
    'promo-code/has-been-activated': 'Promocode has been activated',

    // Critical error messages use english texts as keys, because
    // translator _may_ not be available when displaying them
    'Something went wrong': 'Something went wrong',
    'Incident has been reported and we will work to solve it':
        'Incident has been reported and we will work to solve it.',
    'Go back': 'Go back',
    'Try again': 'Try again',

    'diet-faq/title': 'Questions',

    'account/activate/title': 'Account activation',
    'account/activate/error': 'Account activation failed.',

    'coupon/title': 'Activation',
    'coupon/continue': 'Continue with:',
    'coupon/register-user/title': 'Sign up',
    'coupon/access-code': 'Access code',
    'coupon/login/title': 'Log in',
    'coupon/activate/title': 'Enter your access code',
    'coupon/activated-successfully': 'The coupon has been activated',
    'coupon/activate-card/title': 'Activate the access code',
    'coupon/card-instruction': 'To use the access card:',
    'coupon/card-instruction/1': 'fill in all fields of the form',
    'coupon/card-instruction/2': 'enter the code from your card',
    'coupon/card-instruction/3':
        'if all the data is correct, click the button.',
    'coupon/send': 'Activate access',

    'agreements/terms-and-conditions/text': 'I accept the ',
    'agreements/terms-and-conditions/text2': 'Terms',
    'agreements/terms-and-conditions/text3': ' and ',
    'agreements/terms-and-conditions/text4': 'Privacy Policy',
    'agreements/terms-and-conditions/text5':
        ' and agree to the processing of my personal data',
    'agreements/processing-personal-data/text':
        'Wyrażam zgodę na przetwarzanie moich danych osobowych',
    'agreements/processing-personal-data/text-more':
        'przez DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako administratora danych osobowych, w celu realizacji usług dostępnych w serwisie oraz w celach marketingowych, w tym na otrzymywanie na podany adres e-mail informacji handlowej wysłanej przez DietLabs sp. z. o.o. sp. k. w imieniu własnym lub na zlecenie jej partnerów biznesowych. Masz prawo wglądu do Twoich danych oraz ich poprawienia lub usunięcia.',

    'newsletter/unsubscribe/title': 'We are sorry that you are leaving',
    'newsletter/unsubscribe/success':
        'The email address has been unsubscribed from the newsletter.',
    'newsletter/unsubscribe/send': 'Unsubscribe me',
    'read-more': 'more',

    'cart/title': 'Cart',
    'cart/payment-method': 'Payment method',
    'cart/free-delivery-info':
        'Add products for {{ price }} to have free shipping.',
    'cart/delivery/heading': 'Delivery address',
    'cart/delivery/name': 'First and last name or company name',
    'cart/delivery/street': 'Street, house and flat number',
    'cart/delivery/postal-code': 'Postal code',
    'cart/delivery/city': 'City',
    'cart/delivery/phone': 'Phone number',
    'cart/delivery/country': 'Country',
    'cart/summary': 'Order summary',
    'cart/summary/products': 'Total products',
    'cart/summary/delivery': 'Delivery',
    'cart/summary/total': 'Total',
    'cart/pay': 'Pay',

    'cart/upsell/you-save': 'You save',
    'cart/upsell/add-product': 'Add to cart',
    'cart/upsell/about': 'About product',
    'cart/upsell/added': 'Added',
    'cart/next': 'Next',
    'cart/back-to-cart': 'Back to cart',
    'cart/confirm-and-order': 'Confirm and order',
    'cart/change': 'change',
    'cart/order-summary': 'Order summary',
    'cart/address-of-delivery': 'Address of delivery',
    'cart/payment': 'Payment',
    'cart/payment/error-occured':
        'Sorry, but we could not start your payment. Please try again. If the problem persists, please let us know.',
    'cart/payment-cod/error-occured':
        'Sorry, but we could not process your order. Please try again. If the problem persists, please let us know.',
    'cart/order-is-pending': 'Order has been received.',
    'cart/login-or': 'or',
    'cart/accept-condition/validation/required':
        'Consent is required to proceed',

    'thank-you/title': "Thank you for your order — what's next?",
    'thank-you/how-to-activate-the-service': 'How to activate the service?',
    'thank-you/step1': 'Check your e-mail. You will receive a order summary.',
    'thank-you/step2a': 'We will start preparing your order for shipping.',
    'thank-you/step2b':
        'We will start preparing your order as soon as your payment gets processed.',
    'thank-you/step2c':
        'You will be informed when in separate e-mail, as soon as your order is ready for shipping.',
    'thank-you/step3':
        'In next e-mail you will receive a tracking code and an instruction how to track your shipment. Up to 91% of shipments get delivered within two workind days.',
    'thank-you/activation-code-and-instruction':
        'Activation code and instruction how to use it will be included in your order.',
    'thank-you/flyer-content1':
        'Do not set yourself on slimming as a torment, which you need to survive with pain and pain.',
    'thank-you/flyer-content2':
        'Consider dropping unnecessary kilograms as a challenge that you will joyfully meet.',
    'thank-you/flyer-content3': '- Anna Lewandowska',
    'thank-you/flyer-footer':
        'Go to {{site_url}}/aktywacja, enter the code below and activate the service.',
    'thank-you/go-to-diet-plan': 'Go to meal plan',
    'thank-you/start-configuring-your-diet': 'Start configuring your meal plan',
    'thank-you/start-configuring-your-diet/button': 'Configure the diet',
    'thank-you/warning':
        'ATTENTION! In order to take full advantage of the website resources, activate your account. We have sent you the activation link to the following address: {{email}}. If you have not received the e-mail please check your spam folder.',

    'loading/message': 'Loading…',
    'et-al/abbrev': 'et al.',
    'et-al/full': 'et alii',

    'payment/pending/title': 'You ordered: {{ products }}',
    'payment/pending/description':
        'You will get access to your service when we process your payment. Waiting time depends on the method of payment you have selected.',
    'payment/pending/make-payment-description':
        'If you do not pay yet, you can do it now.',
    'payment/pending/make-payment': 'Pay',

    'message/showing-cached-data': 'Showing cached data.',
    'message/showing-cached-data/network-error':
        'Failed to fetch data — shown data may be stale.',
    'message/showing-cached-data/network-error-offline':
        'No Internet connection — shown data may be stale.',

    'units/mililiter/full': 'mililiter',
    'units/mililiter/full_plural': 'mililiters',
    'units/mililiter/abbr': 'ml',

    'units/ounce/full': 'ounce',
    'units/ounce/full_plural': 'ounces',
    'units/ounce/abbr': 'oz',

    'route-title/hydration-day': 'Your hydration',
    'menu/hydration': 'Hydration',
    'menu/your-hydration': 'Your hydration',
    'hydration/add-custom': 'Add your own portion of water',
    'hydration/goal-label': 'Daily target:',
    'hydration/goal-changer-label': 'Daily hydration target',

    'hydration/goal-header/reached': 'The daily target has been achieved.',
    'hydration/goal-reached-label': 'Congratulations!',
    'hydration/goal-reached1': "You've reached your daily hydration goal. ",
    'hydration/goal-reached2':
        'Remember, good hydration contributes to the proper functioning of the body.',

    'hydration/goal-left/past/pre-value': 'Today you should drink another',
    'hydration/goal-left/past/post-value': 'of water',
    'hydration/goal-left/present/pre-value': 'Today you should drink another',
    'hydration/goal-left/present/post-value': 'of water',
    'hydration/goal-left/future/pre-value': '{{date}} you should drink',
    'hydration/goal-left/future/post-value': 'water',
    'hydration/goal-reached':
        'Congratulations! You have reached your daily hydration goal!',
    'hydration/progress/label': 'drunk',
    'hydration/remove-last-portion': 'Remove the last portion of water',
    'route-title/hydration-add': 'Add portion of water',
    'hydration/add-portion': 'Add portion',
    'hydration/add/heading': 'How much water did you drink?',
    'hydration/add/submit': 'Add',
    'hydration/add/cancel': 'Cancel',
    'hydration/add/custom': 'own',
    'hydration/full-glass': 'Full glass',
    'hydration/full-bottle': 'Full bottle',
    'hydration/add-global-action': 'Add a portion of water',
    'route-title/hydration-history': 'Hydration history',
    'menu/hydration-history': 'History',
    'hydration/go-to-history': 'History',
    'hydration/back-to-hydration': 'Go back',
    'hydration/recommended-value': '{{value}} (recommended)',
    'hydration/glass-value': '{{value}} (glass)',
    'hydration/glass': 'glass',
    'hydration/half-bottle-value': '{{value}} (half\xa0a\xa0bottle)',
    'hydration/bottle-value': '{{value}} (bottle)',
    'hydration/bottle': 'bottle',
    'mindfulness/title': 'Balance',

    'fav-meals/added': 'Added to favorites.',
    'fav-meals/removed': 'Deleted from favorites.',
    'fav-meals/back': 'Undo',
    'fav-meals/added-to-dayplan': 'Meal added to the menu.',
    'fav-meals/added-to-dayplan-today': "Meal added to today's menu.",
    'fav-meals/added-to-dayplan-tomorrow': "Meal added to tomorrow's menu.",
    'fav-meals/insert-into-dayplan': 'Add meal to your menu',
    'fav-meals/insert-today': 'Today',
    'fav-meals/insert-tomorrow': 'Tomorrow',
    'fav-meals/insert-date': 'Choose date',

    'calendar/january': 'January',
    'calendar/february': 'February',
    'calendar/march': 'March',
    'calendar/april': 'April',
    'calendar/may': 'May',
    'calendar/june': 'June',
    'calendar/july': 'July',
    'calendar/august': 'August',
    'calendar/september': 'September',
    'calendar/october': 'October',
    'calendar/november': 'November',
    'calendar/december': 'December',

    'calendar/monday': 'M',
    'calendar/tuesday': 'T',
    'calendar/wednesday': 'W',
    'calendar/thursday': 'T',
    'calendar/friday': 'F',
    'calendar/saturday': 'S',
    'calendar/sunday': 'S',

    'calendar/reset': 'Reset',
    'calendar/ok': 'OK',
    'route-title/achievements': 'Achievements',
    'achievements/all-achievements': 'All achievements',
    'achievements/not-yet-achieved': 'Not yet achieved',
    'achievements/category/trainings': 'Trainings',
    'achievements/title/trainings': 'Workouts completed',
    'achievements/category/fasting': 'Fasting',
    'achievements/title/fasting': 'Fasting goal reached',
    'achievements/category/hydration': 'Hydration',
    'achievements/title/hydration': 'Daily goals reached',
    'diet-type/veggie': 'Healthy Vegetarian',
    'diet-type/classic': 'Classic Zone',

    'diet-is-ready/title': 'Your meal plan is ready to go',
    'diet-is-ready/content':
        'We have created a custom meal plan that will help you reach your goal. To change the data, go to the meal plan settings.',
    'diet-is-ready/ok': 'OK, thanks',
    'diet-is-ready/change-settings': 'Change settings',

    'terms-of-service/title': 'Terms of service',
    'terms-of-service/android': 'Purchase in Google Play',
    'terms-of-service/ios': 'Purchase in App Store',
    'terms-of-service/web': 'Purchase in website',

    'intetmittent-fasting/end-of-post':
        'You are still fasting. End your current fast.',
    'intetmittent-fasting/next-eat-window-today': 'Fast until today',
    'intetmittent-fasting/next-eat-window-tomorrow': 'Fast until tomorrow',
    'intetmittent-fasting/eat-window-to-today': 'Eating window ends today, at',
    'intetmittent-fasting/eat-window-to-tomorrow':
        'Eating window ends tomorrow, at',

    'buy/sign-up': 'Sign up',
    'buy/acceptance':
        'The core features of Cukrzyca app cannot work without personal data regarding your body measurements, activity, nutrition and physical state. Do you consent to the processing by Dietlabs sp. z o.o. data about your body measurements, activity, nutrition and physical state in Cukrzyca such as age, height, weight and other activity-related data?',
    'buy/continue-with-e-mail': 'or continue with e-mail',
    'buy/email': 'E-mail address',
    'buy/continue': 'Continue',
    'buy/already-have-an-account': 'Already have an account?',
    'buy/log-in': 'Log in',
    'buy/back-to': 'Back to',
    'buy/thank-you/title': 'Thank you for subscribing to the Cukrzyca app.',
    'buy/thank-you/sent-confirmation': "We've sent confirmation to",
    'buy/thank-you/download app':
        'Download the Cukrzyca app\nand log in to your account.',
    'buy/thank-you/token-error': 'Your payment token is invalid',

    'download-the-app/content': 'Your account is all set & ready!',
    'download-the-app/mobileApps':
        'If you don’t have my app just yet, scan QR code visible below or go to the Apple App Store or Google Play and search for “ML.fitness”',

    'developer-options/api-gateway': 'API Gateway',
    'developer-options/set-api-gateway': 'Ustaw API Gateway',
    'developer-options/api-gateway-changed': 'API Gateway zostało zmienione',
    'developer-options/clear-data': 'Wyczyść dane',
    'developer-options/clear-apollo-cache': 'Wyczyść Apollo Cache',
    'developer-options/apollo-cache-cleared':
        'Apollo Cache został wyczyszczony',
    'developer-options/clear-local-storage': 'Wyczyść Local Storage',
    'developer-options/local-storage-cleared':
        'Local Storage został wyczyszczony',
    'developer-options/remove-service-worker': 'Usuń Service Worker',
    'developer-options/service-worker-removed':
        'Service Worker został wyrejestrowany',
    'developer-options/create-new-user': 'Utwórz nowego użytkownika',
    'developer-options/new-user-created': 'Nowy użytkownik został utworzony',
    'developer-options/add-access': 'Dodaj dostęp',
};
